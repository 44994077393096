import { VisibilityTwoTone } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const TableRowHtml = ({ customCol, rows, routeAction, fieldAction}) => {
  const navigate = useNavigate()
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        component={Paper}
        elevation={4}
        sx={{ height:"100%" }}
      >
        <Table stickyHeader aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              {customCol.map((col, i) => (

                <TableCell
                  component="th"
                  scope="cell"
                  padding="none"
                  key={col.headerName + uuidv4()}
                  sx={{
                    fontWeight: "bold",
                    background: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.common.white,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.warning.main,
                  }}
                  style={{
                    //textAlign: "center",
                    minWidth: col.minWidth,
                  }}
                  align="left"
                >
                  {col.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i + uuidv4()}>
                {customCol.map((col, i) => {
                  if (col.field !== "action") {
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        key={i + uuidv4()}
                      >
                        {row[col.field]
                          ?.toString()
                          ?.split("<br>")
                          .map((res, key) => {
                            return <div key={key}>{res}</div>;
                          })}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"

                        key={i + uuidv4()}
                      >
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={()=> navigate(`${routeAction}/${row[fieldAction]}`)}
                        >
                          <VisibilityTwoTone />
                        </IconButton>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableRowHtml;
