import React from "react";
import {
  AirlineStopsTwoTone,
  CropFreeTwoTone,
  DepartureBoard,
  ListAltSharp,
  ListAltTwoTone,
  LocalShippingTwoTone,
  LocationOnTwoTone,
  MenuBookTwoTone,
  PointOfSale,
  SearchTwoTone,
  StartTwoTone,
  TravelExploreTwoTone,
  WarehouseTwoTone,
  WrongLocationTwoTone,
  EventRepeat,
} from "@mui/icons-material";
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { Chip } from "@mui/material";
import {
  amber,
  blue,
  cyan,
  deepPurple,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import InputIcon from '@mui/icons-material/Input';
import StartIcon from '@mui/icons-material/Start';
export const vociMenu = [
  {
    menuName: "Rilevazione misure colli",
    icon: <LocalShippingTwoTone color="action" />,
    route: "/rileva-colli",
    title: "Rilevazione misure",
    width: "40%",
    colorIcon: "primary",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    visible: true,
    idMenu: 1,
    subtitle: "Spedizioni",
  },
  {
    menuName: "Carico merce in partenza. Spunta manuale visiva.",
    icon: <AirlineStopsTwoTone color="action" />,
    route: "/carico-merce-in-uscita",
    title: "Carico merce in uscita",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(244,0,255,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 2,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Entrata posizionamento a magazzino",
    icon: <LocationOnTwoTone color="action" />,
    route: "/posizione-merce-mag-entrata",
    title: "Entrata posizionamento a magazzino",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 3,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Uscita da magazzino logistico (RiferimPaletta)",
    icon: <WrongLocationTwoTone color="action" />,
    route: "/posizione-merce-mag-uscita",
    title: "Uscita da magazzino logistico (RiferimPaletta).",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 4,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Inventario",
    icon: <ListAltTwoTone color="action" />,
    route: "/inventario-magazzino",
    title: "Inventario magazzino",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 5,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Partenza per distribuzione",
    icon: <CropFreeTwoTone color="action" />,
    route: "/partenza-distribuzione",
    title: "Barcode partenza per distribuzione",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 6,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Arrivo da trazione",
    icon: <DepartureBoard color="action" />,
    route: "/arrivo-da-trazione",
    title: "Barcode arrivo da trazione.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 7,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Partenza per trazione",
    icon: <StartTwoTone color="action" />,
    route: "/partenza-per-trazione",
    title: "Barcode partenza per trazione",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 8,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Assegnare SSCC a RiferimPaletta",
    icon: <ListAltSharp color="action" />,
    route: "/assegnazione-sscc-to-rifpaletta",
    title: "Assegnare SSCC a RiferimPaletta",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 9,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Inventario HUB.",
    icon: <MenuBookTwoTone color="action" />,
    route: "/inventario-hub",
    title: "Inventario HUB.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 10,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Cerca colli per controllo qualità",
    icon: <SearchTwoTone color="action" />,
    route: "/cerca-colli-controllo-qualita",
    title: "Cerca colli per controllo qualità.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 12,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  
 // 12/06/2023
  {
    menuName: "Rientro da distribuzione",
    icon: <ModeOfTravelIcon color="action" />,
    route: "/rientro-da-distribuzione",
    title: "Barcode Rientro da distribuzione.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 13,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },

  //04/03/2024 Greg
  {
    menuName: "Merce in arrivo da distinta",
    icon: <ModeOfTravelIcon color="action" />,
    route: "/arrivo-distinta",
    title: "Distinta in entrata",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 16,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
///////
  {
    menuName: "Uscita da magazzino logistico",
    icon: <StartIcon color="action" />,
    route: "/merce-mag-log-uscita",
    title: "Uscita da magazzino.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 14,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },

  {
    menuName: "Entrata in magazzino logistico",
    icon: <InputIcon color="action" />,
    route: "/merce-mag-log-entrata",
    title: "Entrata in magazzino.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 15,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },

  {
    menuName: "Spunta merce in uscita da magazzino logistico",
    icon: <StartIcon color="action" />,
    route: "/merce-mag-log-uscita",
    title: "Uscita da magazzino.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 14,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },

  {
    menuName: "Spunta merce in entrata in magazzino logistico ( ubicazioni )",
    icon: <InputIcon color="action" />,
    route: "/merce-mag-log-entrata",
    title: "Entrata in magazzino.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 15,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },

    {
    menuName: "Spunta merce in uscita da magazzino logistico ( ubicazioni )",
    icon: <StartIcon color="action" />,
    route: "/merce-mag-log-uscita-ubi",
    title: "Uscita da magazzino.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 17,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },

  {
    menuName: "Spunta merce in entrata in magazzino logistico ( ubicazioni )",
    icon: <InputIcon color="action" />,
    route: "/merce-mag-log-entrata-ubi",
    title: "Entrata in magazzino.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 18,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
];

export const menuSubheder = [
  {
    menuName: "Spedizioni",
    icon: <PointOfSale />,
    colorIcon: "primary",
    idMenu: 2,
  },
  {
    menuName: "Logistica",
    icon: <WarehouseTwoTone />,
    colorIcon: "secondary",
    idMenu: 0,
  },
  {
    menuName: "Viaggi",
    icon: <TravelExploreTwoTone />,
    colorIcon: "warning",
    idMenu: 1,
  },
  /* {
    menuName: "Magazzino",
    icon: <TravelExploreTwoTone />,
    colorIcon: "info",
    idMenu: 3,
  }, */
];

export function isMobile() {
  const { innerWidth: width } = window;
  if (width > 600) {
    return false;
  } else {
    return true;
  }
}



export const getConfig = async (params) => {
  let config = { baseUrl: "", visibleMenu: [], group: [] };
  let splitPrm = params.split("/");

  let parametro = splitPrm[2];
  let response ={};

  //const response = await fetch(`https://configuration-irs.incontraweb.it/?developer=loris`)
  // const response = await fetch(
  //   `https://configuration-irs.incontraweb.it/?domain=${
  //     parametro === "192.168.1.102:3000" ? "localhost:3000" : parametro
  //   }&${Math.floor(Math.random() * 3)}`
  // );
    if (parametro === "localhost:3000"){
    response = await fetch(
      `https://configuration-irs.incontraweb.it/?domain=${parametro}`
    )
  }
  else{
    response = await fetch(
      `https://${parametro}/config`
    );
  }
  const data = await response.json();
  config.baseUrl = data.server;

  //05/02/2024 Greg: Rimossi i seguenti. spostati su api magappconfig
  // let menuChoseByClient = data.visibleMenuMagApp;
  // let menuHeader = data.visibleMenuMagApp_group;

  // let menu = [];
  // menuChoseByClient.forEach((element) => {
  //    let voci = {
  //      ...vociMenu.find((res) => res.idMenu === element.idMenu),
  //      menuName: element.menuName,
  //      idMenuGroup: element.idMenuGroup,
  //    };
  //    menu = [...menu, voci];
  //  });
  // let group = [];
  // menuHeader.forEach((element) => {
  //   let voci = menuSubheder.find((res) => element.idMenu === res.idMenu);
  //   if(voci !== undefined){
  //     group = [...group, voci];
  //   }
  // });
  // config.group = group;
  // config.visibleMenu = menu;
  return config;
};
export function chipIconState(StateTracking, desc) {
  let icon = <></>;
  switch (StateTracking) {
    case "INS":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AddTaskTwoTone />}
          sx={{ background: cyan[100], color:"black"  }}
          size="small"
        />
      );
      break;
      case "INSDIS":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AddTaskTwoTone />}
          sx={{ background: yellow[50], color:"black"  }}
          size="small"
        />
      );
      break;
    case "PRC":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<SwipeDownTwoTone />}
          sx={{ background: cyan[100], color:"black" }}
          size="small"
        />
      );
      break;
    case "ASS1":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<EditRoadTwoTone />}
          sx={{ background: yellow[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "RIT":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<TransferWithinAStationTwoTone />}
          sx={{ background: pink[100], color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "MAG1":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<WarehouseTwoTone />}
          sx={{ background: red[100], color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "HUB1":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<DeviceHubTwoTone />}
          sx={{ background: indigo[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "TRAZ":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<DoubleArrowTwoTone />}
          sx={{ background: blue[100], color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "TRAN":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<DirectionsTwoTone />}
          sx={{ background: teal[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "HUB2":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<HubTwoTone />}
          sx={{ background: lime[100], color:"black"  }}
          size="small"
        />
      );
      break;
    case "MAG2":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AdminPanelSettingsTwoTone />}
          sx={{ background: lime.A100, color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "GIA":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<PanToolTwoTone />}
          sx={{ background: red.A100, color:"black"  }}
          size="small"
        />
      );
      break;
    case "AFF":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<AirlineStopsTwoTone />}
          sx={{ background: yellow.A100 , color:"black"  }}
          size="small"
        />
      );
      break;
    case "ASS2":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<HowToRegTwoTone />}
          sx={{ background: deepPurple[100] , color:"black"  }}
          size="small"
        />
      );
      break;
    case "INC":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<LocalShippingTwoTone />}
          sx={{ background: amber[200] , color:"black"  }}
          size="small"
        />
      );
      break;
    case "CON":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<CheckCircleOutlineTwoTone />}
          sx={{ background: green.A100, color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "RDE":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          //icon={<ConnectWithoutContactTwoTone />}
          sx={{ background: orange[50], color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "RTV":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          sx={{ background: orange[50], color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "NRIT":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          sx={{ background: red.A100, color:"black"  }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "NCON":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          sx={{ background: purple.A200, color:"black" }}
          variant="outlined"
          size="small"
        />
      );
      break;
    case "INR":
      icon = (
        <Chip
          label={desc ? desc : StateTracking}
          icon={<EventRepeat />}
          sx={{ background: red.A400, color:"black" }}
          variant="outlined"
          size="small"

        />
      );
      break;
    default:
      return icon;
  }
  return icon;
}