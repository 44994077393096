import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import {  PosizionamentoContext } from "../contexts";
import { useContext } from "react";
import { Search } from "@mui/icons-material";
import { MagMovimTable } from "../components";
import {  
  IconButton,
  InputAdornment,

} from "@mui/material";

const CAUSALE_USCITA = "usc";

const MagLogUscita = () => {
  let navigate = useNavigate();
  const inputRef = React.useRef();
  

const {
    state:{
        RicercaColumns,
        RicercaMovimenti,
        
    },
    getRicercaMagMovimenti,
    getMagMovimDataUscita,
    
} = useContext(PosizionamentoContext);

  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];
  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    getRicercaMagMovimenti(CAUSALE_USCITA);

  }, []);

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      inputRef.current.blur();
      if (barcodeForm.code && isValid) {
        //getPartenzaTrazione(barcodeForm.code, navigate);
      }
    }
  }; 

  return (
    <>

      <Stack direction={"row"} spacing={2} m={2}>

        <TextField
          id="outlined-basic"
          label="Barcode"
          variant="outlined"
          placeholder="Inserire il barcode"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          /* onClick={()=> inputRef.current.blur()}
              onDoubleClick={()=> inputRef.current.focus()} */
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          onChange={(e) => changeHandler("code", e.target.value)}
          error={Boolean(touched.code && errors.code)}
          //helperText={touched.code && errors.code}
          value={barcodeForm.code || ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" disabled={!isValid}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>


      <MagMovimTable 
        columnsData={RicercaColumns} 
        rowsData={RicercaMovimenti} 
        navigate={navigate} 
        causale={CAUSALE_USCITA} 
        detailFunc={getMagMovimDataUscita} 
        detailPath={"merce-mag-log-uscita-dettaglio"}
      />
              

    </>
  );
};
export default MagLogUscita;